.footer {
    background-color: #000;
    color: #fff;
    padding: 40px 0;
  }
  
  .footer h4 {
    margin-bottom: 20px;
  }
  
  .footer p, .footer ul, .footer address {
    font-size: 14px;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    text-decoration: underline;
  }
  
  .footer .form-group {
    margin-bottom: 20px;
  }
  
  .footer .form-control.newsletter-input {
    border-radius: 4px;
    border: none;
    padding: 10px;
  }
  
  .footer .btn.newsletter-btn {
    border-radius: 4px;
    color: #fff;
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
  }
  
  .footer .btn.newsletter-btn:hover {
    background-color: #0056b3;
  }
  
  .footer i {
    margin-right: 10px;
    color: #fff;
    font-size: 16px;
    vertical-align: middle;
  }
  