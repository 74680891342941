.hero-section {
    background: #f8f9fa;
    padding: 60px 0;
  }
  
  .hero-section h1 {
    font-size: 48px;
    font-weight: 700;
  }
  
  .hero-section p {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .hero-img img {
    max-width: 100%;
  }
  
  .clients.section-bg {
    padding: 60px 0;
  }
  
  .contact {
    background: #fff;
    padding: 60px 0;
  }
  
  .contact-form {
    background: #f8f9fa;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h4 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .contact-form .form-control {
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  .contact-form .btn {
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
  }
  
  .contact-form .btn:hover {
    background-color: #0056b3;
  }
  
  .contact-details {
    list-style: none;
    padding: 0;
    font-size: 16px;
  }
  
  .contact-details li {
    margin-bottom: 10px;
  }
  